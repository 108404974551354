import React, { useState, useMemo } from "react";
import "./App.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { occupations } from "./occupations";
import { words } from "./words";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import LinkIcon from "@mui/icons-material/Link";

function App() {
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const [expanded, setExpanded] = useState(false);
  const [currentWords, setCurrentWords] = useState(null);
  const [currentOccupation, setCurrentOccupation] = useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const generateWords = () => {
    const splitWords = words.split(",");
    shuffle(splitWords);
    setCurrentWords(
      `${splitWords[0]}, ${splitWords[1]}, ${splitWords[2]}, ${splitWords[3]}, ${splitWords[4]}, ${splitWords[5]}`
    );
  };

  const generateCards = () => {
    const splitWords = currentWords != null ? currentWords.split(", ") : [];
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Card variant="elevation" sx={{ minWidth: 100 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {splitWords[0]}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card variant="elevation" sx={{ minWidth: 100 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {splitWords[1]}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card variant="elevation" sx={{ minWidth: 100 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {splitWords[2]}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card variant="elevation" sx={{ minWidth: 100 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {splitWords[3]}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card variant="elevation" sx={{ minWidth: 100 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {splitWords[4]}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card variant="elevation" sx={{ minWidth: 100 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {splitWords[5]}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const generateOccupation = () => {
    const splitOccupations = occupations.split(",");
    const randomNum = getRandomArbitrary(0, splitOccupations.length);
    setCurrentOccupation(splitOccupations[randomNum]);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Helmet>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8535352312870646"
            crossorigin="anonymous"
          ></script>
          <meta charSet="utf-8" />
          <meta property="og:site_name" content="SERPENT GREASE" />
          <meta property="og:title" content="Serpent Grease" />
          <meta property="og:url" content="https://www.serpentgrease.com" />
          <meta
            name="twitter:title"
            content="Serpent Grease - An online client based on the board game Snake Oil"
          />
          <meta name="twitter:url" content="https://www.serpentgrease.com" />
          <meta
            name="description"
            content="A simple online client honoring the board game Snake Oil. Use this inferface to play with your friends over video chat!"
          />
          <title>
            Serpent Grease - An online client based on the board game Snake Oil!
          </title>
        </Helmet>
        <body>
          <Container maxWidth="md">
            <Typography
              variant="h1"
              fontSize="50pt"
              component="div"
              gutterBottom
            >
              Serpent Grease
            </Typography>
            <Typography
              align="center"
              maxWidth={"md"}
              variant="h2"
              component="div"
              gutterBottom
              fontSize="24pt"
            >
              An online client based on the board game Snake Oil!
            </Typography>
            <Typography
              align="center"
              maxWidth={"md"}
              variant="h3"
              component="div"
              gutterBottom
              fontSize="12pt"
            >
              This is a site that I created to allow people to play the game
              Snake Oil online with other people. It's a board game I've loved
              playing over the years, and since there is no official online
              generator, and the only other paid version can be found on
              Tabletop Simulator, I figured why not make a version that's
              accessible to everyone? All you need is this site, and a video
              chat client. Follow the instructions, and have fun! And if you
              like this site
              <Link href="https://snakeoilgame.com" target="_blank">
                ...buy the board game.
              </Link>
            </Typography>
            <hr></hr>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="instructionsbh-content"
                id="instructionsbh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Instructions
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Want to play with your friends? Here's how!
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography gutterBottom align="left">
                  1. Start a video chat with all your friends (Zoom, Slack,
                  Skype, etc.). Designate one person to be the Grease Master
                  (host).
                </Typography>
                <Typography gutterBottom align="left">
                  2. The Grease Master will generate a new occupation, and
                  assign the occupation to the customer for the current round.
                </Typography>
                <Typography gutterBottom align="left">
                  3. The other players will generate a new hand of words and
                  have 30 seconds to combine two words to create a "greasy"
                  product for the customer.
                </Typography>
                <Typography gutterBottom align="left">
                  4. One by one the players will pitch their product to the
                  customer for a minimum of 45 seconds. The customer can cut off
                  any player that goes over the time limit.
                </Typography>
                <Typography gutterBottom align="left">
                  5. After each player has made a pitch, the customer picks the
                  best item and gives the player a point.
                </Typography>
                <Typography gutterBottom align="left">
                  6. Pick a new customer and the Grease Master will generate a
                  new occupation for that player. Play repeats until everyone
                  has been a customer at least once.
                </Typography>
                <Typography gutterBottom align="left">
                  At the end of a cycle, the player with the most points wins!
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="occupationbh-content"
                id="occupationbh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  (Grease Master only) Occupation
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Starting a new round? Generate a new occupation here.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Button
                  variant="contained"
                  onClick={() => {
                    generateOccupation();
                  }}
                >
                  Generate An Occupation
                </Button>
                <Card variant="elevation" sx={{ minWidth: 100 }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {currentOccupation}
                    </Typography>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="handbh-content"
                id="handbh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Your hand
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Generate six new words to create a brand new greasy product!
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Button
                  variant="contained"
                  onClick={() => {
                    generateWords();
                  }}
                >
                  Generate Your Words
                </Button>
                <Typography>{generateCards()}</Typography>
              </AccordionDetails>
            </Accordion>
            <hr></hr>
            <Button
              href="https://megapower.games"
              target="_blank"
              variant="contained"
              startIcon={<LinkIcon />}
              align="left"
            >
              <Typography fontWeight={"bold"}>Mega Power Games</Typography>
            </Button>
          </Container>
        </body>
      </div>
    </ThemeProvider>
  );
}

export default App;
